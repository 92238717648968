<template>
  <div class="goldenSentenceIndex">
    <!-- 搜索 -->
    <div class="goldenSentenceIndex-search">
      <div class="searchBox">
        <!-- <div class="searchBox-left">
               <el-select v-model="value" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
             </div> -->
        <div class="searchBox-input">
          <el-input
            v-model="input"
            @keyup.enter.native="searchList"
            placeholder="请输入搜索内容"
          ></el-input>
        </div>
        <div class="searchBox-btn" @click="searchList">
          <img :src="require('assets/images/idiom.png')" alt="" />
          <img :src="require('assets/images/included_search.png')" alt="" />
        </div>
      </div>
      <dropDownList
        class="dropDownList"
        ref="dropList"
        :groupId="2"
        @handleClick="handleClick"
      />
    </div>
    <!-- 列表 -->
    <div class="goldenSentenceIndex-list">
      <div class="list-theme" ref="theme">
        <div class="list-theme-item">
          <div class="list-theme-item-text">主题</div>
          <!-- <img src="../../assets/images/goldenSentence/theme-icon.png" alt="" class="list-theme-img"> -->
        </div>
        <div class="list-theme-content">
          <div
            class="list-theme-content-item"
            v-for="(item, index) in themeList"
            :key="index"
            @click="themeClick(item)"
            :title="item.conName"
          >
            {{ item.conName }}

            <!-- {{ item.conName }} -->
          </div>
        </div>
        <i
          class="list-theme-icon"
          :class="
            themeIconShow == true
              ? 'list-theme-icon-up'
              : 'list-theme-icon-down'
          "
          v-if="themeList.length > 3"
          @click="themeControl"
        ></i>
      </div>
      <div class="list-title">
        <div
          class="list-title-item"
          v-for="(item, index) in sortArr"
          :key="index"
          style="cursor: pointer"
          @click="sortClick(item)"
        >
          <div class="list-title-item-text">{{ item.title }}</div>
          <i
            class="list-title-icon up"
            :class="item.sort == 'asc' ? 'up' : 'down'"
          ></i>
        </div>
      </div>
      <div class="list-content" v-if="listData != ''">
        <div
          class="list-content-item"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="list-content-item-up">
            <i
              class="list-content-item-add"
              onclick="handleCopyName(item)"
              @click="copyName(item)"
            ></i>
            <div class="list-content-title">
              {{ index + 1 }}.
              <span v-html="item.oftenWord"></span>
              <i
                class="list-content-collection"
                :class="item.collected == 1 ? 'collection' : ''"
                @click="collection(item)"
              ></i>
            </div>
          </div>
          <div class="list-content-item-down">
            <i
              class="list-content-item-down-icon"
              @click="copyFileName(item)"
            ></i>
            <div class="list-content-item-down-text">{{ item.source }}</div>
          </div>
        </div>
      </div>
      <div class="list-kong" v-else>
        <img :src="require('assets/images/kong.png')" />
        <div>暂无搜索结果</div>
      </div>
    </div>

    <!-- 分页 -->
    <div
      class="footer-paging"
      v-if="Math.ceil(total / limit) > 1 && collectionPage == false"
    >
      <el-pagination
        v-if="listData.length != 0"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 15, 20, 25]"
        :pager-count="5"
        :current-page="page"
        :page-size="limit"
        @size-change="handleSizeChange1"
        @current-change="currentChange1"
        :total="total"
      >
      </el-pagination>
    </div>

    <div class="page" v-if="Math.ceil(total / limit) > 1 && collectionPage">
      <el-pagination
        v-if="listData.length != 0"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 15, 20, 25]"
        :pager-count="5"
        :current-page="page"
        :page-size="limit"
        @current-change="currentChange"
        @size-change="handleSizeChange"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 提示 -->
    <div class="tips" v-if="collect == true">
      {{ collect == true ? "收藏成功" : "收藏失败" }}
    </div>
    <!-- 错误提示 -->
    <div class="errorTips" v-if="errorTips == true">
      <i class="errorTips-icon"></i>
      <div class="errorTips-text">{{ errorTipsText }}</div>
    </div>
    <!-- 成功提示 -->
    <div class="successTips" v-if="successTips == true">
      <i class="successTips-icon"></i>
      <div class="successTips-text">{{ sucessTipsText }}</div>
    </div>

    <!-- 弹窗 -->
    <popup @handleClose="handleClose"></popup>
  </div>
</template>

<script>
import dropDownList from "components/drop-down-list/dropDownList";
import IdiomPager from "components/idiom/pager/IdiomPager";
import { findSelectedLib } from "api/infoCompile";
import popup from "components/goldenSentencel/dialog";
import {
  oftenLibraryList,
  findOftenWord,
  findOftenLibraryType,
  collectOftenWord,
  collectList,
  templateDownload,
} from "api/goldenSentenceIndex.js";
export default {
  components: {
    popup,
    dropDownList,
    // IdiomPager,
  },
  data() {
    return {
      value: "",
      input: "",
      sortArr: [
        {
          title: "时间",
          sort: "desc",
          id: 1,
        },
        {
          title: "热度",
          sort: "desc",
          id: 2,
        },
        {
          title: "引用时间",
          sort: "desc",
          id: 3,
        },
        {
          title: "引用频率",
          sort: "desc",
          id: 4,
        },
      ],
      listData: [],
      collect: false, //收藏
      total: null, //总条数
      page: 1, //页码
      limit: 10, //每页几条
      errorTips: false, //错误提示
      errorTipsText: "句库名称重复，请更换",
      successTips: false, //成功提示
      sucessTipsText: "",
      tableBarList: [],
      libStr: 99, //句库id
      sortType: 1, //排序类型
      sortVal: "desc", //排序类型
      isOften: 0, //收藏金句传1  默认是0
      oftenType: "", //主题id
      themeList: [], //主题列表
      themeIconShow: false, //控制图标显示  true 展开 false关闭
      collectionPage: false,
    };
  },
  methods: {
    async TemplateDownload(labelId) {
      const params = {
        labelId,
      };

      const res = await templateDownload(params);
    },
    handleClose() {
      this.$refs["dropList"].getLibGroup(2);
    },
    handleClick() {
      this.inquire();
    },
    handleSizeChange1(val) {
      this.limit = val;
      this.getFindOftenWord(this.oftenType);
    },

    handleSizeChange(val) {
      this.limit = val;
      this.getCollectList();
    },
    // 查询选中库
    async inquire() {
      const params = {
        groupId: 2,
      };
      this.collectionPage = false;
      const res = await findSelectedLib(params);

      this.getTheme(res.data.libraryId);
    },

    // 主题展开关闭
    themeControl() {
      this.themeIconShow = !this.themeIconShow;
      if (this.themeIconShow) {
        this.$refs.theme.style.height = "130px";
        this.$refs.theme.style.overflowY = "auto";
      } else {
        this.$refs.theme.style.height = "45px";
        this.$refs.theme.style.overflowY = "hidden";
      }
    },
    // 收藏
    async collection(item) {
      if (item.collected == 0) {
        let params = {
          type: 2,
          id: item.id,
        };
        const res = await collectOftenWord(params);

        if (res.code === 200) {
          this.$message({
            type: "success",
            message: "收藏成功！",
            duration: "1000",
          });
          this.getFindOftenWord(this.oftenType);
        } else {
          this.$message({
            type: "error",
            message: res.message,
            duration: "1000",
          });
        }
      } else if (item.collected == 1) {
        let params = {
          type: 2,
          id: item.id,
        };
        const res = await collectOftenWord(params);
        if (res.code === 200) {
          this.$message({
            type: "success",
            message: "取消收藏！",
            duration: "1000",
          });
          this.getFindOftenWord(this.oftenType);
        } else {
          this.$message({
            type: "error",
            message: res.message,
            duration: "1000",
          });
        }
      }
    },
    // 搜索
    searchList() {
      this.page = 1;
      this.limit = 10;
      this.getFindOftenWord(this.oftenType);
    },
    // 复制全部文章
    copyName(item) {
      let domUrl = document.createElement("input");
      domUrl.value = item.oftenWord;
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      let creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      this.$message({
        type: "success",
        message: "复制成功！",
        duration: "1000",
      });

      this.TemplateDownload(item.labelId);
    },
    handleCopyName(item) {
      window.alert("插入内容 : " + item.oftenWord);
      window.external.InsertLeft(item.oftenWord);
    },
    // 复制文件名
    copyFileName(item) {
      let domUrl = document.createElement("input");
      domUrl.value = item.source;
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      let creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      this.$message({
        type: "success",
        message: "复制成功！",
        duration: "1000",
      });
    },
    // 获取通用、vip语库列表
    async getOftenLibraryList(id) {
      this.tableBarList = [];
      let formData = new FormData();
      formData.append("libType", id);
      const res = await oftenLibraryList(formData);
      if (res.code === 200) {
        this.tableBarList = res.data;
        if (id == 1) {
          let obj = {
            name: "收藏金句",
            id: 1,
          };
          this.tableBarList.unshift(obj);
        }
      } else {
        this.$message({
          type: "error",
          message: res.message,
          duration: "1000",
        });
      }
    },
    // 排序
    sortClick(item) {
      if (item.sort == "desc") {
        item.sort = "asc";
      } else {
        item.sort = "desc";
      }
      this.sortType = item.id;
      this.sortVal = item.sort;

      this.page = 1;
      this.limit = 10;

      this.getFindOftenWord(this.oftenType);
    },
    // 查询常用语句
    async getFindOftenWord(id) {
      // let formData = new FormData();
      // if (this.libStr) formData.append("libIdStr", this.libStr);
      // if (this.oftenType) formData.append("oftenType", this.oftenType);
      // if (this.input) formData.append("oftenWord", this.input);
      // if (this.value) formData.append("libType", this.value);
      // if (this.sortType) formData.append("sortType", this.sortType);
      // if (this.sortVal) formData.append("sortVal", this.sortVal);
      // if (this.isOften) formData.append("isOften", this.isOften);
      // formData.append("pageIndex", this.page);
      // if (this.limit) formData.append("pageSize", this.limit);
      const params = {
        page: this.page,
        limit: this.limit,
        id,
        oftenWord: this.input,
        type: 2,
        sortType: this.sortType,
        sortVal: this.sortVal == "desc" ? 1 : 2,
      };

      const res = await findOftenWord(params);

      if (res.code === 200) {
        this.listData = res.data.data;
        // this.listData = [];
        this.total = res.data.total;
      } else {
        // this.$message({
        //   type: "error",
        //   message: res.message,
        //   duration: "1000",
        // });
      }
    },
    // 上一页

    currentChange1(val) {
      this.page = val;
      this.getFindOftenWord(this.oftenType);
    },
    // 获取主题
    async getTheme(id) {
      // let formData = new FormData();
      // formData.append("libId", this.libStr);
      const params = {
        id,
      };
      const res = await findOftenLibraryType(params);

      if (res.code === 200) {
        this.themeList = res.data;
        this.oftenType = res.data[0].parentId;

        this.getFindOftenWord(this.oftenType);
      } else if (res.code === 2001) {
        this.themeList = [];
        this.listData = [];
        this.oftenType = id;
        this.getFindOftenWord(this.oftenType);
        // this.$message({
        //   type: "error",
        //   message: res.message,
        //   duration: "1000",
        // });
      }
    },
    // 主题查询列表
    themeClick(item) {
      this.oftenType = item.id;
      this.isOften = 0;
      this.input = "";
      this.page = 1;
      this.sortType = 1;
      this.sortVal = "desc";
      this.limit = 10;
      this.getFindOftenWord(this.oftenType);
    },
    // 收藏金句
    collectSentence: function () {
      this.isOften = 1;
      this.libStr = "";
      this.input = "";
      this.value = "";
      this.page = 1;
      this.limit = 10;
      this.collectionPage = true;
      this.getCollectList();
    },
    // 核心金句
    coreSentence: function () {
      this.isOften = 0;
      this.value = "";
      this.input = "";
      this.page = 1;
      this.limit = 10;
      this.libStr = 99;
      this.srotType = 1;
      this.sortVal = "desc";
      this.inquire();
    },
    // 免费金句库
    freeSentence: function (id) {
      this.isOften = 0;
      this.value = "";
      this.input = "";
      this.page = 1;
      this.limit = 10;
      this.libStr = id;
      this.srotType = 1;
      this.sortVal = "desc";
      this.inquire();
    },

    // 收藏列表
    async getCollectList() {
      const params = {
        type: 2,
        limit: this.limit,
        page: this.page,
      };

      const res = await collectList(params);
      this.listData = res.data.data;
      this.total = res.data.total;
    },

    currentChange(val) {
      this.page = val;
      this.getCollectList();
    },
  },
  created() {
    this.inquire();
  },
  mounted: function () {
    this.$refs["dropList"].getLibGroup(2);
    window.collectSentence = this.collectSentence;
    window.coreSentence = this.coreSentence;
    window.freeSentence = this.freeSentence;
  },
};
</script>

<style lang="scss">
.el-tooltip__popper {
  background: #fff !important;
  color: #ff6900 !important;
  border: 1px solid #ff6900 !important;

  .popper__arrow::after {
    border-bottom-color: #ff6900 !important;
  }
}
</style>

<style lang="scss" scoped>
.list-theme-content-item {
  ::v-deep .el-tooltip.item {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.goldenSentenceIndex {
  background-color: #fff;
  position: relative;
  margin-bottom: 20px;
  &-search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    position: relative;

    .dropDownList {
      position: absolute;
      left: 35px;
    }
    .searchBox {
      height: 47px;
      background: #ffffff;
      // border: 1px solid #d9dee4;
      border: 1px solid #ff6900;

      width: 750px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      position: relative;
      &-input {
        margin-left: 10px;
        height: 26px;
        flex: 1;
        width: 80%;
        ::v-deep .el-input {
          height: 26px;
          .el-input__inner {
            height: 26px;
            line-height: 26px;
            border: none;
          }
        }
      }
      &-btn {
        height: 100%;
        // height: 40px;
        padding: 0 10px;
        background: #ff6900;
        border-radius: 0 5px 5px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        > img:first-child {
          width: 52px;
          height: 22px;
          margin: 0 10px;
        }
      }
    }
  }
  &-list {
    .list-theme {
      position: relative;
      margin: 10px 30px 0;
      height: 45px;
      line-height: 45px;
      background: #ffbf6914;
      border-radius: 5px;
      overflow-y: hidden;
      display: flex;
      &-item {
        height: 45px;
        display: flex;
        position: sticky;
        margin-left: 20px;
        top: 0px;

        &-text {
          width: 50px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .list-theme-img {
          width: 13px;
          height: 13px;
          margin-left: 6px;
          transform: translateY(15px);
        }
      }
      &-content {
        display: flex;
        align-items: center;
        margin-left: 30px;
        height: 45px;
        flex-wrap: wrap;
        width: 90%;
        line-height: 45px;
        &-item {
          font-size: 14px;
          width: 130px;
          margin-right: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
          cursor: pointer;
          @include ellipsis();
          &:hover {
            color: #ff6900;
          }
        }
      }
      &-icon {
        position: sticky;
        right: 19px;
        top: 16px;
        height: 14px;
        width: 14px;
        cursor: pointer;
      }
      &-icon-up {
        @include backgroundGroup(
          "../../assets/images/goldenSentence/theme-up.png"
        );
      }
      &-icon-down {
        @include backgroundGroup(
          "../../assets/images/goldenSentence/theme-down.png"
        );
      }
    }
    .list-title {
      display: flex;
      align-items: center;
      padding: 29px 31px 0 29px;
      &-item {
        display: flex;
        align-items: center;
        margin-right: 39px;
        .list-title-item-text {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .list-title-icon {
          width: 9px;
          height: 13px;
          margin-left: 6px;
          @include backgroundGroup(
            "../../assets/images/goldenSentence/sortIcon.png"
          );
        }
        .up {
          @include backgroundGroup(
            "../../assets/images/goldenSentence/sortIcon-blue-up.png"
          );
        }
        .down {
          @include backgroundGroup(
            "../../assets/images/goldenSentence/sortIcon-blue.png"
          );
        }
      }
    }
    .list-content {
      margin-top: 13px;
      .list-content-item {
        padding: 0px 31px 21px 29px;
        &:hover {
          background: #ffbf6914;
        }
        .list-content-item-up {
          display: flex;
          line-height: 26px;
          padding-top: 16px;
          .list-content-item-add {
            width: 18px;
            height: 17px;
            @include backgroundGroup(
              "../../assets/images/goldenSentence/subTitle-icon.png"
            );
            margin-right: 11px;
            cursor: pointer;
            transform: translateY(4px);
          }
          .list-content-title {
            width: 99%;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #000000;
            cursor: pointer;
            .list-content-collection {
              display: inline-block;
              width: 17px;
              height: 16px;
              cursor: pointer;
              @include backgroundGroup(
                "../../assets/images/goldenSentence/collection.png"
              );
              transform: translateY(2px);
            }
            .collection {
              @include backgroundGroup(
                "../../assets/images/goldenSentence/collection-red.png"
              );
            }
          }
          .list-content-form {
            margin-left: 35px;
            width: 90px;
            padding: 0 10px;
            color: #ffffff;
            background: #ff6900;
            border-radius: 50px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            text-align: center;
            @include ellipsis();
          }
        }
        .list-content-item-down {
          margin-left: 57px;
          margin-top: 20px;
          display: flex;
          &-icon {
            width: 14px;
            height: 14px;
            @include backgroundGroup(
              "../../assets/images/goldenSentence/subTitle-icon.png"
            );
            margin-right: 5px;
            cursor: pointer;
          }
          &-text {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;
          }
        }
      }
    }
    .list-kong {
      height: 600px;
      text-align: center;

      img {
        margin-top: 100px;
        width: 150px;
        height: 150px;
      }

      div {
        margin-top: 10px;
        text-align: center;
        color: #999;
        font-size: 16px;
      }
    }
  }
  .footer-paging {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    .paging-icon-left {
      width: 24px;
      height: 24px;
      @include backgroundGroup(
        "../../assets/images/goldenSentence/paging-left.png"
      );
      cursor: pointer;
    }
    .paging-text {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      margin: 0 10px;
    }
    .paging-icon-right {
      width: 24px;
      height: 24px;
      @include backgroundGroup(
        "../../assets/images/goldenSentence/paging-right.png"
      );
      cursor: pointer;
    }
    .right {
      @include backgroundGroup(
        "../../assets/images/goldenSentence/paging-blue-right.png"
      );
    }
    .left {
      @include backgroundGroup(
        "../../assets/images/goldenSentence/paging-blue-left.png"
      );
    }
  }
  .tips {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 111px;
    height: 46px;
    background: #7f7f7f;
    border-radius: 22px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
    line-height: 46px;
    text-align: center;
  }
  // 报错提示
  .errorTips {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 47px;
    background: #ffffff;
    border: 1px solid #ff7668;
    border-radius: 22px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ff7668;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    z-index: 10000;
    .errorTips-icon {
      width: 22px;
      height: 22px;
      background-image: url("../../assets/images/goldenSentence/error.png");
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 9px;
    }
  }
  // 成功提示
  .successTips {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 46px;
    background: #ff6900;
    border-radius: 22px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    z-index: 10000;
    .successTips-icon {
      width: 21px;
      height: 21px;
      background-image: url("../../assets/images/goldenSentence/success.png");
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 9px;
    }
  }
}
</style>
